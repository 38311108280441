/* ::v-deep .itemListGridNoPage .avue-crud .el-table {
    height: calc(100vh - 450px) !important;
    max-height: calc(100vh - 450px) !important;
    box-sizing: border-box;
  }

  .header-box {
    margin-top: 5px;
    margin-bottom: 5px;

    .box-card {
      background: rgb(25, 144, 255);
      height: 100px;
      font-size: 18px;
      color: #FFFFFF;
      width: calc(25% - 20px);

      .margin-t-10 {
        margin-top: 10px;
      }
    }
  }

  .shuttleBackBox {
    width: 100%;
    justify-content: space-between;

    .table-box {
      width: calc(100% - 280px);
    }
  } */